
.outerShell{
    /* padding: 2rem; */
    background-color: black;
    border-top: 3px solid #c3bab0;
    padding-top: 18px;
    height: 85px;
    min-width: 855px;
}
.navLink{
    padding-right: 1.618em;
    /* padding-left: 1.618em; */
    text-decoration: none;
    display: flex;
    align-items: center;
    color:white;
}

.navbar{
   display:flex;
   margin: 0 auto; /* This centers the navbar */
   max-width: 70em; /* Makes the navbar take up 80% of the container's width */
   /* max-width: 1600px; You can adjust this value to set how wide the navbar can get */
   margin-left: auto;
   margin-right: auto;
   max-width: 70em;
   padding-right: 1.618em;
   padding-left: 1.618em;
}

.companyName{
  flex-grow: 1;
  font-size: 40px !important;
  min-width: 270px;

}

.navElements{
    flex-grow: 2;
    justify-content: center;
    display: flex;
    /* min-width: 600px; */
}

.social{
    flex-grow: 1;
    justify-content: flex-end;
    display: flex;
    padding:0 !important;

}


.social svg {
 
  }