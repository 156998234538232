.outterShell {
    position: relative;
    background-color: #141414;
    color: white;
    padding: 0.618em;
    font-size: 18px;
    z-index: 1;
}

/* Apply background image with transparency and gradient using a pseudo-element */
.outterShell::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        to bottom, 
        rgba(38, 38, 38, 1) 0%,   /* Solid at the top */
        rgba(38, 38, 38, 0.1) 25%, /* Fade to more transparent */
        rgba(38, 38, 38, 0.1) 75%, /* Transparent in the middle */
        rgba(38, 38, 38, 1) 100%  /* Solid at the bottom */
    ),
    url('../../img/brick.jpg'); /* Background image */
    opacity: 0.1; /* Adjust transparency */
    background-blend-mode: multiply; /* Ensures the gradient affects the image */
    z-index: -1; /* Position it behind the content */
    pointer-events: none; /* Ensure the image doesn't block content interaction */
}

/* To ensure the content is positioned correctly */
.outterShell > * {
    position: relative;
    z-index: 2;
}

.dragonImage{
    height: 7rem;
    margin: 0.2rem 0.6rem;
}

.flex{
    display: flex;
    align-items: center;
    justify-content: center;
}