@import url('https://fonts.googleapis.com/css?family=Metropolis:400,500,700');

/* body {
  margin: 0;
  font-family: "Figtree", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Metropolis
} */

body {
  margin: 0;
  font-family: 'Cinzel Decorative', cursive;
  min-width: 850px;
}

h1, h2, h3 {
  font-family: 'Cinzel Decorative', cursive;
  font-weight: 700;
}