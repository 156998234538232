
*{
    box-sizing: border-box;
}

.landingPage{
    padding: 3.236em;
    background-image: url(../../img/cp-bg.png);
    background-size: cover;
    background-repeat: round;
}

.logoImg{
  width:50rem;
}

.backgroundSection{
  background-image: url(../../img/bricks.png);
  background-size: cover;
  background-repeat: round;
  padding: 3.236em;

 
}

@media (max-width: 1123px) {
  .landingPage{
    padding: 3.236em;
   background-color: #262626;
   background-image: none;
}

.logoImg{
  width:700px
}

.backgroundSection{
  background-image: url(../../img/dirt.png);
  background-color: #262626;
  padding: 3.236em;

 
}
}

.landingText{
    margin-left: auto;
    margin-right: auto;
    max-width: 70em;
    padding-right: 1.618em;
    padding-left: 1.618em;

}

.descriptionText{
    text-align: center;
    font-size: 20px;
    max-width: 70em;
    padding-right: 1.618em;
    padding-left: 1.618em;
    padding-bottom: 1.618em;
}

.valuesOuterDiv {
    color: white;
    display: flex;
    align-items: center; /* Align items vertically in the center */
    gap: 20px; /* Optional: adds space between the text and image */
    /* align-items: flex-start; */
    margin-left: auto;
    margin-right: auto;
    max-width: 70em;
    padding-right: 1.618em;
    padding-left: 1.618em;
    position: relative;
    z-index: 1;
    
}

.valueText{
    font-size: 20px;
}

.valueText, .valueImg {
    flex: 1; 
    text-align: left;
}

.valueImg img {
    width: 100%; /* Makes the image responsive */
    height: auto; /* Maintain aspect ratio */
    border-radius: 80%;

  
    
}

.dirtTexture{

  background-image: url(../../img/dirt.png);
  position: absolute;
  height: 100%;
  width: 100%;;
  opacity: 0.3;
    background-color: #262626;
    /* max-height: 900px; */

 
}

.teamInfo {
    padding: 3.236em;
    background-color:#2d2a28;
    background-size: cover;
    display:flex;
    
    
  }
  
  .teamInfoContainer {
    color: white;
    justify-content: space-evenly;
    list-style-type: none; /* Removes default list styling */
    padding: 0;
    margin: 0 auto; /* Centers the container */
    max-width: 70em;
    display: flex;
    flex-direction: row; /* Aligns team members vertically */
    gap: 20px; /* Adds space between team members */
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    
  }
  
  .teamMember {
    display: flex;
    flex-direction: column;
    align-items: center; /* Aligns items vertically */
    gap: 20px; /* Adds space between the image and details */
    max-height: 350px;
    min-width: 219px;
    
  }
  
  .teamMemberImage {
    width: 150px; /* Adjust as needed */
    height: 150px; /* Adjust to maintain aspect ratio */
    object-fit: cover; /* Ensures the image covers the area nicely */
    border-radius: 2%; /* Makes the image circular */
    box-shadow: 0 0 20px rgba(54, 66, 228, 0.8), /* Outer glow */
    0 0 40px rgba(0, 255, 255, 0.6), /* Medium glow */
    0 0 60px rgba(0, 255, 255, 0.4); /* Inner glow */
  }
  
  .teamMemberDetails {
    flex: 1;
    padding:1.618em;
    max-height: 180px;
    max-width: 240px;

  }
  
  .teamMemberDetails h2 {
    margin: 0 0 10px 0; /* Adjusts spacing around the name */
  }
  
  .teamMemberDetails p {
    margin: 0; /* Removes default margin */


  }
  .gamesInfo{
    padding: 3.236em;
    background-color: #581AA4; 
    background-size: cover;
    
}

.gamesInfoContainer{
    margin-left: auto;
    margin-right: auto;
    max-width: 70em;
    padding-right: 1.618em;
    padding-left: 1.618em;
    display: flex;
    justify-content: flex-start;
    
}

.homeGames {
  padding:0px;
  margin: 0px;
    
}

.homeGames li{
  background: linear-gradient(to right, #0A1A44, #0D47A1);
    list-style-type: none; /* Removes default list styling */
    padding: 3.236em;
    color: #fff;
    background-size: cover;
    min-height: 500px;


    /* box-shadow: inset 5px 20px 20px rgba(0,0,0,.5); */
}

.gamesPoster {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 285px; /* Width of the poster */
    height: 393px; /* Height of the poster */

  }
  
  .gamesPoster img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Cover the container without losing aspect ratio */
    object-position: center; /* Center the image within the container */
    opacity: 1; /* Fully opaque */
    transition: none 0s ease 0s; /* No transition effect */
    margin-right: 1.618em;
  }

  .gamesText{
    padding-left: 3.236em;
  }

  .gamesText h3{
    text-align: left;
  }

  .gamesText a{
    text-decoration: unset;
    color: white;
    font-size: 50px;
  }
  .gamesLinks{
    text-align: left;

  }

  .homeGames1 li{
    background: linear-gradient(to left, #0A1A44, #0D47A1);
      list-style-type: none; /* Removes default list styling */
      padding: 3.236em;
      color: #fff;
      background-size: cover;
      min-height: 500px;
      
  
  
      /* box-shadow: inset 5px 20px 20px rgba(0,0,0,.5); */
  }

  .homeGames1{
    margin:0px;
    padding: 0px;
  }

