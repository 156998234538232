
*{
    box-sizing: border-box;
    color: white;
}

.landingPage{
  padding: 0; /* Remove padding if you want the image to truly cover everything; adjust as necessary */
  height: 40vh; /* Make the section take up the full viewport height */
  background-image: url('../../img/ColorCatcher.webp');
  background-color: black;
  /* opacity: 70%; */
  background-size: cover;

  background-size: contain; /* Ensures the entire image is visible and retains its size */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-position: center; /* Centers the image */
  background-attachment: fixed; /* Keeps the image fixed in place relative to the viewport */
}
/* Media query for smaller screens */
@media (max-width: 1950px) {
  .landingPage {
    height: 50vh; /* Adjust height for smaller screens if needed */
    background-size: cover; /* Use cover to fill the space while ensuring the image looks good */
    background-position: center; /* Center the image */
    background-attachment: scroll; /* Allows the image to scroll with the page on smaller screens */
  }
}

/* Media query for very small screens (e.g., mobile devices) */
@media (max-width: 480px) {
  .landingPage {
    height: 60vh; /* Further adjust height if needed */
    background-size: cover; /* Continue using cover for a good visual fit */
    background-position: center; /* Keep the image centered */
    background-attachment: scroll; /* Allow scrolling with the page */
  }
}

.landingText{
    margin-left: auto;
    margin-right: auto;
    max-width: 70em;
    padding-right: 1.618em;
    padding-left: 1.618em;
    display:flex;
    justify-content: center;
    padding: 7%;
    font-size: 4rem;


}

.gameDemoImage {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.imgMerge {
  position: relative;
  width: 100%; /* Adjust width as needed */

}

.gameDemoGif {

  display: block;
  height: 738px;
  width: 507px;
  border-radius: 70px;

}
.ios{
  padding: 1.6rem;
}
.google{
  padding: 1.6rem;
}

.phone {
  position: absolute;
  top: -578px;
  left: -250px;
  height: auto;
  object-fit: contain;
  width: 897px;
  height: 1894px;
}

.descriptionText{
    text-align: center;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 70em;
    padding-right: 1.618em;
    padding-left: 1.618em;
    color: white;
    justify-content: center;
}

.h2{
  padding: 1.6rem;
  margin: 0px;
}

.backgroundSection{
  background-image: url(../../img/dirt.png);
  background-color: #262626;
  padding: 3.236em;
}

.gameText{
  padding: 1.6rem;
  
}
.gameTitle{
  font-size: 4rem;
}

.gameDemoFlex{
  display: flex;
  justify-content: center;
  align-content: stretch;
  align-items: center;
}

.gameDemoGif{
  max-width: 410px;
  padding: 1.6rem;
}


.gameDemoText{

  max-width: 50em;
  padding: 1.6rem;
  text-align: left;
  font-size: 1.2rem;
}
.downloads{
  text-align: center;
}


@media (max-width: 1000px) {
.downloads{
  text-align: center;
}
}


@media (max-width: 1450px) {
  .gameDemoFlex{
    display: flex;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    flex-direction: column;
}
}