.pageWidth{
    background-image: url(../../img/dirt.png);
    background-color: #262626;
    padding: 3.236em;
    height: 100rem;

}
.flex{
    display: flex;
    align-items: center;
    justify-content: center;
}

.h2{
    font-size: 3rem;
}